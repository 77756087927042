import React, { useEffect, useState } from 'react'
import Spinner from '../../components/Home/Spinner'
import { Link } from 'react-router-dom'

const ProjectSection = () => {
    const [items, setItems] = useState([])
    const [errorMessage, setErrorMessage] = useState(false)
    const [length, setLength] = useState(0)
    const [loading, setLoading] = useState(true)

    const fetchData = async () => {
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/posts/project-short-list/`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            }
        })
        const data = await response.json()
        console.log(data)
        if (response.status === 200) {
            setItems(data)
            setLength(data.length)
        }
        else {
            setErrorMessage(true)
        }
        setLoading(false)
    }
    useEffect(() => {
        fetchData()
    }, [])

if(errorMessage){
    return(
            <section className="bg-recent-project">
                <div className="container">
                    <div className="row">
                        <div className="recent-project">
                            <div className="section-header">

                                <h4 className='text-center text-success mt-4'>PROJELER GETİRİLEMEDİ</h4>
                                 
                                    <button className='btn btn-default mt-4 text-dark' onClick={fetchData}>{loading ?'Yenileniyor': 'Yenile'}</button>                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>

    )
}

    if (!errorMessage && length === 0) {
        return (
            <section className="bg-recent-project">
                <div className="container">
                    <div className="row">
                        <div className="recent-project">
                            <div className="section-header">

                                <h4 className='text-center text-success mt-4'>PROJELERİMİZ PLANLAMA AŞAMASINDA...</h4>
                                <Spinner></Spinner>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <section className="bg-recent-project">
            <div className="container">
                <div className="row">
                    <div className="recent-project">
                        <div id="filters" className="button-group mt-4">
                            <a href='/project-list' className="button is-checked">HEPSİNİ GÖSTER</a>
                        </div>
                        <div className="portfolio-items">
                            {
                                items.map((item, index) =>

                                    <div className="item cat-1" data-category="transition" key={index}>
                                        <div className="item-inner">
                                            <div className="portfolio-img">
                                                <div className="overlay-project"></div>
                                                <img src={item.image} alt="recent-project-img-1" />
                                                <ul className="project-link-option">
                                                    <li className="project-link"><a href={`/project-detail/${item.slug}`}><i className="fa fa-link" aria-hidden="true"></i></a></li>
                                                </ul>
                                            </div>
                                            <div className="recent-project-content">
                                                <h4><a href={`/project-detail/${item.slug}`}>{item.caption}</a></h4>
                                                <p><a href={`/project-detail/${item.slug}`}><i className="fa fa-calendar" aria-hidden="true"></i> {new Date(item.date).toLocaleDateString()}</a></p>

                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default ProjectSection