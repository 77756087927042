import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import HomePage from './pages/Home/HomePage';
import NotFound from './pages/Home/NotFound';
import ProjectList from './pages/Project/ProjectList';
import ProjectDetail from './pages/Project/ProjectDetail';
import BlogList from './pages/Blog/BlogList';
import BlogDetail from './pages/Blog/BlogDetail';
import Login from './pages/User/Login';
import Team from './pages/Team/Team';
import ImageList from './pages/ImageGallery/ImageList';
import BlogUpdate from './pages/Blog/BlogUpdate';
import BlogCreate from './pages/Blog/BlogCreate';
import ProjectUpdate from './pages/Project/ProjectUpdate';
import ProjectCreate from './pages/Project/ProjectCreate';

export default function RootRouter() {
    return (

        <Router>
            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/project-create' element={<ProjectCreate />} />
                <Route path='/project-list' element={<ProjectList />} />
                <Route path='/project-detail/:slug/' element={<ProjectDetail />} />
                <Route path='/project-update/:slug/' element={<ProjectUpdate />} />
                <Route path='/blog-list' element={<BlogList />} />
                <Route path='/blog-detail/:slug/' element={<BlogDetail />} />
                <Route path='/login' element={<Login />} />
                <Route path='/team' element={<Team />} />
                <Route path='/image-gallery' element={<ImageList />} />
                <Route path='/blog-update/:slug' element={<BlogUpdate />} />
                <Route path='/blog-create' element={<BlogCreate />} />
                <Route path='*' element={<NotFound />} />


            </Routes>

        </Router>

    )
}
