import React, { useState, useEffect } from 'react'
import Layout from '../../components/Home/Layout'
import { useNavigate } from 'react-router-dom'
const Login = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState(false)
    const navigate = useNavigate()
    const loginUser = async (e) => {
        e.preventDefault();
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/users/login/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ username: username, password: password })
        })
        const data = await response.json()
        console.log(data)
        if (response.status === 200) {            
            localStorage.setItem('token',data.token)
            navigate('/')
        }
        else {
            setErrorMessage(true)
        }
    }
    useEffect(() => {
        const t = setTimeout(() => {
            setErrorMessage(false);
        }, 2000);
        return () => {
            clearTimeout(t);
        }
    }, [errorMessage])
    return (
        <Layout>
            <div className='container'>
                <div className='row mt-5 d-flex justify-content-center'>
                    <h2 className='text-center'>Giriş Sayfası</h2>
                    {
                        errorMessage &&
                        <div className='d-flex justify-content-center mt-3'>
                            <p className='text-danger'>
                                Kullanıcı Bilgileri Hatalı. Lütfen Tekrar Kontrol Ediniz.
                            </p>
                        </div>
                    }
                    <div className='col-10'>

                        <form onSubmit={loginUser}>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Kullanıcı Adı</label>
                                <input required type="text" onChange={(e) => setUsername(e.target.value)} className="form-control form-control-lg" aria-describedby="emailHelp" placeholder="Kullanıcı Adı" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Parola</label>
                                <input required type="password" onChange={(e) => setPassword(e.target.value)} className="form-control form-control-lg" placeholder="Parola" />
                            </div>
                            <div className='d-flex justify-content-center'>

                                <button type="submit" className="btn btn-success mt-3">GİRİŞ</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Login