import React from 'react'
import Navbar from './Navbar'
import { Helmet } from 'react-helmet'
const Layout = ({children,title,content}) => {
  return (
    <>
    <Helmet>
        <title>{title}</title>
        <meta name='description' content={content}/>
    </Helmet>
    <Navbar/>
    <div>
        {children}        
    </div>
    </>
  )
}

export default Layout