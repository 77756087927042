import React, { useEffect, useState } from 'react'
import Layout from '../../components/Home/Layout'
import Spinner from '../../components/Home/Spinner'
import { Link } from 'react-router-dom'
const BlogList = () => {
    const [items, setItems] = useState([])
    const [errorMessage, setErrorMessage] = useState(false)
    const [length, setLength] = useState(0)
    const [loading, setLoading] = useState(true)

    const fetchData = async () => {
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/posts/blog-list/`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            }
        })
        const data = await response.json()
        console.log(data)
        if (response.status === 200) {
            setItems(data)
            setLength(data.length)
        }
        else {
            setErrorMessage(true)
        }
        setLoading(false)
    }
    useEffect(() => {
        fetchData()
    }, [])

    if (errorMessage) {
        return (
            <Layout>

                <section className="bg-page-header">
                    <div className="page-header-overlay">
                        <div className="container">
                            <div className="row">
                                <div className="page-header">
                                    <div className="page-title">
                                        <h2>Blog Yazılarımız</h2>
                                    </div>

                                    <div className="page-header-content">
                                        <ol className="breadcrumb">
                                            <li><a href="/">Home</a></li>
                                            <li>Blog</li>
                                        </ol>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </section>
                <section className="bg-blog-section">
                    <div className="container">
                        <div className="row">
                            <div className="blog-section blog-page">
                                <div className="row">
                                    <div className="col-12 text-center">

                                        <h4 className='text-center text-success mt-4'>BİR HATA OLUŞTU!</h4>

                                        <button className='btn btn-default mt-4 text-dark' onClick={fetchData}>{loading ? 'Yenileniyor' : 'Yenile'}</button>

                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </section>

            </Layout>

        )
    }

    if (!errorMessage && length === 0) {
        return (
            <Layout>

                <section className="bg-page-header">
                    <div className="page-header-overlay">
                        <div className="container">
                            <div className="row">
                                <div className="page-header">
                                    <div className="page-title">
                                        <h2>Blog Yazılarımız</h2>
                                    </div>

                                    <div className="page-header-content">
                                        <ol className="breadcrumb">
                                            <li><a href="/">Home</a></li>
                                            <li>Blog</li>
                                        </ol>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </section>
                <section className="bg-blog-section">
                    <div className="container">
                        <div className="row">
                            <div className="blog-section blog-page">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <h4 className='text-center text-success mt-4'>BLOGLARIMIZ YAZIM AŞAMASINDA...</h4>
                                        <Spinner></Spinner>

                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </section>
            </Layout>
        )
    }


    return (
        <Layout>
            <section className="bg-page-header">
                <div className="page-header-overlay">
                    <div className="container">
                        <div className="row">
                            <div className="page-header">
                                <div className="page-title">
                                    <h2>Blog Yazılarımız</h2>
                                </div>

                                <div className="page-header-content">
                                    <ol className="breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li>Blog</li>
                                    </ol>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </section>

            <section className="bg-blog-section">
                <div className="container">
                    <div className="row">
                        <div className="blog-section blog-page">
                            <div className="row">
                                {
                                    items.map((item, index) =>
                                        <div className="col-lg-4 col-sm-6 col-12" key={index}>
                                            <div className="blog-items">
                                                <div className="blog-img">
                                                    <a href={`/blog-detail/${item.slug}`}><img src={item.image} alt="blog-img-2" className="img-responsive" /></a>
                                                </div>

                                                <div className="blog-content-box">
                                                    <div className="blog-content">
                                                        <h4><a href={`/blog-detail/${item.slug}`}>{item.caption}</a></h4>
                                                        <p>{item.description}</p>
                                                    </div>

                                                    <div className="meta-box">
                                                        <ul className="meta-post">
                                                            <li><i className="fa fa-calendar" aria-hidden="true"></i> {new Date(item.date).toLocaleDateString()}</li>

                                                            <li><a href={`/blog-detail/${item.slug}`}><i className="fa fa-commenting-o" aria-hidden="true"></i> {item.commentCount} Yorum</a></li>
                                                            {
                                                                localStorage.getItem('token') &&
                                                                <li><a href={`/blog-update/${item.slug}`}><i className="fa fa-pencil" aria-hidden="true"></i> Güncelle</a></li>
                                                            }
                                                        </ul>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                    )
                                }
                            </div>


                        </div>

                    </div>

                </div>

            </section>


        </Layout>
    )
}

export default BlogList