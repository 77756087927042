import React from 'react'

const FooterSection = () => {
  return (
    <footer>
    <div className="bg-footer-top">
        <div className="container">
            <div className="row">
                <div className="footer-top">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 col-12">
                            <div className="footer-widgets">
                                <div className="widgets-title">
                                    <h3>ANASAYFA</h3>
                                </div>



                            </div>

                        </div>

                        <div className="col-lg-3 col-sm-6 col-12">
                            <div className="footer-widgets">
                                <div className="widgets-title">
                                    <h3>PROJELERİMİZ</h3>
                                </div>

                            </div>

                        </div>

                        <div className="col-lg-3 col-sm-6 col-12">
                            <div className="footer-widgets">
                                <div className="widgets-title">
                                    <h3>BLOG YAZILARIMIZ</h3>
                                </div>


                            </div>

                        </div>

                        <div className="col-lg-3 col-sm-6 col-12">
                            <div className="footer-widgets">
                                <div className="widgets-title">
                                    <h3>EKİBİMİZ</h3>
                                </div>


                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>
        
    </div>
    

    <div className="bg-footer-bottom">
        <div className="container">
            <div className="row">
                <div className="footer-bottom">
                    <div className="copyright-txt">
                        <p>&copy; 2023. Designer By <a href="#" title="Al-Amin(Web Designer)">oktii</a></p>
                    </div>

                    <div className="social-box">
                        <ul className="social-icon-rounded">
                            <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                            <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                            <li><a href="#"><i className="fa fa-google" aria-hidden="true"></i></a></li>
                            <li><a href="#"><i className="fa fa-vimeo" aria-hidden="true"></i></a></li>
                            <li><a href="#"><i className="fa fa-pinterest-p" aria-hidden="true"></i></a></li>
                        </ul>
                    </div>


                </div>

            </div>

        </div>

    </div>


</footer>

  )
}

export default FooterSection