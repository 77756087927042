import React, { useEffect, useState } from 'react'
import Layout from '../../components/Home/Layout'
import Spinner from '../../components/Home/Spinner'
import { Link } from 'react-router-dom'

const ProjectList = () => {

    const [items, setItems] = useState([])
    const [errorMessage, setErrorMessage] = useState(false)
    const [length, setLength] = useState(0)
    const [loading, setLoading] = useState(true)

    const fetchData = async () => {
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/posts/project-list/`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            }
        })
        const data = await response.json()
        console.log(data)
        if (response.status === 200) {
            setItems(data)
            setLength(data.length)
        }
        else {
            setErrorMessage(true)
        }
        setLoading(false)
    }
    useEffect(() => {
        fetchData()
    }, [])

    if (errorMessage) {
        return (
            <Layout>

                <section className="bg-recent-project">
                    <div className="container">
                        <div className="row">
                            <div className="recent-project">
                                <div className="section-header">
                                    <h2>PROJELERİMİZ</h2>
                                    <p>TÜM PROJELERİMİZDE DÜNYAMIZI DAHA YAŞANABİLİR, DAHA GÜZEL VE DOĞA İLE İÇ İÇE BİR DURUMA GETİRMENİN YOLLARINI ARIYORUZ.</p>

                                    <h4 className='text-center text-success mt-4'>BİR HATA OLUŞTU!</h4>

                                    <button className='btn btn-default mt-4 text-dark' onClick={fetchData}>{loading ? 'Yenileniyor' : 'Yenile'}</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>

        )
    }

    if (!errorMessage && length === 0) {
        return (
            <Layout>

                <section className="bg-recent-project">
                    <div className="container">
                        <div className="row">
                            <div className="recent-project">
                                <div className="section-header">
                                    <h2>PROJELERİMİZ</h2>
                                    <p>TÜM PROJELERİMİZDE DÜNYAMIZI DAHA YAŞANABİLİR, DAHA GÜZEL VE DOĞA İLE İÇ İÇE BİR DURUMA GETİRMENİN YOLLARINI ARIYORUZ.</p>

                                    <h4 className='text-center text-success mt-4'>PROJELERİMİZ PLANLAMA AŞAMASINDA...</h4>
                                    <Spinner></Spinner>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }

    return (
        <Layout>

            <section className="bg-page-header">
                <div className="page-header-overlay">
                    <div className="container">
                        <div className="row">
                            <div className="page-header">
                                <div className="page-title">
                                    <h2>PROJELERİMİZ</h2>
                                </div>

                                <div className="page-header-content">
                                    <ol className="breadcrumb">
                                        <li><a href="/">ANASAYFA</a></li>
                                        <li>PROJELER</li>
                                    </ol>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </section>




            <section className="bg-single-recent-project">
                <div className="container">
                    <div className="row">
                        <div className="recent-project">
                            <div className="portfolio-items">
                                {
                                    items.map((item, index) =>
                                        <div className="item cat-1" data-category="transition" key={index}>
                                            <div className="item-inner">
                                                <div className="portfolio-img">
                                                    <div className="overlay-project"></div>

                                                    <img src={item.image} alt="recent-project-img-1" />
                                                    <ul className="project-link-option">
                                                        <li className="project-link"><a href={`/project-detail/${item.slug}`}><i className="fa fa-link" aria-hidden="true"></i></a></li>
                                                    </ul>
                                                </div>

                                                <div className="recent-project-content">
                                                    <h4><a href={`/project-detail/${item.slug}`}>{item.caption}</a></h4>
                                                    <p><span><a href={`/project-detail/${item.slug}`}>{new Date(item.date).toLocaleDateString()}</a></span></p>
                                                    {
                                                        localStorage.getItem('token') &&
                                                        <a href={`/project-update/${item.slug}`}>Güncelle</a>
                                                    }
                                                </div>

                                            </div>

                                        </div>

                                    )
                                }


                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </Layout>

    )
}

export default ProjectList