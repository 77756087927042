import React, { useState, useEffect } from 'react'

const ReplyCommentList = ({ id, reply }) => {
    const [items, setItems] = useState([])
    const [errorMessage, setErrorMessage] = useState(false)
    const [length, setLength] = useState(0)
    const [loading, setLoading] = useState(true)
    const [text, setText] = useState('')
    const [isShow, setIsShow] = useState(false)

    const fetchData = async () => {
        setLoading(true)
        if (id) {

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/posts/reply-comment-list/${id}/`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                }
            })
            const data = await response.json()
            console.log(data)
            if (response.status === 200) {
                setItems(data)
                setLength(data.length)
            }
            else {
                setErrorMessage(true)
            }
            setLoading(false)
        } else {
            console.log('error')
        }
    }
    useEffect(() => {
        fetchData()
    }, [id])

    const replyCreate = async (e) => {
        e.preventDefault();
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/posts/reply-comment-create/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ postComment: id, text: text })
        })
        const data = await response.json()
        console.log(data)
        if (response.status === 201) {
            setText('')
            setIsShow(true)
            setItems([data, ...items])

        }
    }

    const deleteReply = async (replyId) => {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/posts/reply-comment-delete/`, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id: replyId })
        })
        const data = await response.json()
        console.log(data)
        if (response.status === 200) {
            const newList = items.filter(item => item.id !== replyId)
            setItems(newList)

        }
    }

    useEffect(() => {
        const t = setTimeout(() => {
            setIsShow(false);
        }, 2000);
        return () => {
            clearTimeout(t);
        }
    }, [isShow])


    return (
        <div className="comments-option">
            {
                isShow &&
                <div className='d-flex justify-content-center bg-success'>
                    <p className='text-white'>Yanıt Eklendi</p>
                </div>
            }
            {
                reply &&
                <div className="comments-form">

                    <form onSubmit={replyCreate}>

                        <textarea className="form-control comments-textarea" required value={text} onChange={(e) => setText(e.target.value)} placeholder="Yanıt*"></textarea>
                        <button type="submit" className="btn btn-default">Yanıt Gönder</button>
                    </form>
                </div>
            }

            {items.map((item, index) =>
                <div className="reply-items" key={index}>
                    <div className="reply-image">

                        <svg viewBox="0 -960 960 960">
                            <path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z" />
                        </svg>
                    </div>

                    <div className="comments-content">
                        <div className="comments-author-title">
                            <div className="comments-author-name">
                                <h4><a href="#">{item.fullName}</a> - <small>{new Date(item.date).toLocaleDateString()}</small></h4>
                            </div>
                            {
                                localStorage.getItem('token') &&

                                <div className="reply-icon" style={{ marginLeft: '20px', cursor: 'pointer' }}>

                                    <h6 onClick={() => deleteReply(item.id)}><i class="fa fa-trash" aria-hidden="true"></i> Sil</h6>
                                </div>

                            }

                        </div>
                        <p>{item.text}</p>
                    </div>
                </div>

            )}

        </div>
    )
}

export default ReplyCommentList