import React from 'react'

const ServiceSection = () => {
  return (
    <section className="bg-servicesstyle2-section mt-4">
    <div className="container">
        <div className="row">
            <div className="our-services-option">
                <div className="row">
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="our-services-box">
                            <div className="our-services-items">
                                <i className="flaticon-greenhouse"></i>
                                <div className="our-services-content">
                                    <h4><a href="service_single.html">TEMİZ ÇEVRE VE DOĞA </a></h4>
                                    <p>Çevremizi, doğamızı temiz tutmak ve kirletmemek çok önemlidir. Her toplum bunu uygulamakla sorumludur. </p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="our-services-box">
                            <div className="our-services-items">
                                <i className="flaticon-technology"></i>
                                <div className="our-services-content">
                                    <h4><a href="service_single.html">ENERJİ TASARRUFU</a></h4>
                                    <p>Dünyamızın enerji kaynakları sonsuz değildir ve enerji için Dünyamızın kaynakları tüketilmektedir.
                                        Enerji tasarrufu dünyamızı korur.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="our-services-box">
                            <div className="our-services-items">
                                <i className="flaticon-light-bulb"></i>
                                <div className="our-services-content">
                                    <h4><a href="service_single.html">YENİLEBİLİR ENERJİ</a></h4>
                                    <p>Yenilenebilir enerji çevre ve doğa dostu olduğu için tercih edilmelidir. Bu sayede ekolojik hayat ve iklim daha sağlıklı var olacaktır.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="our-services-box">
                            <div className="our-services-items">
                                <i className="flaticon-recycling-symbol"></i>
                                <div className="our-services-content">
                                    <h4><a href="service_single.html">GERİ DÖNÜŞÜM</a></h4>
                                    <p>Kaynaklarımızın sınırlı olması ve üretilen çoğu maddenin doğaya atık olarak atılmaması için geri dönüşüm hayati önem arz etmektedir.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="our-services-box">
                            <div className="our-services-items">
                                <i className="flaticon-sprout"></i>
                                <div className="our-services-content">
                                    <h4><a href="service_single.html">ORMANLARI KORUMAK</a></h4>
                                    <p>Canlı hayatı, ekolojik denge ve iklim değişikliğinden korunmak için ormanları korumak yaşamın olmazsa olmazıdır.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="our-services-box">
                            <div className="our-services-items">
                                <i className="flaticon-droplet"></i>
                                <div className="our-services-content">
                                    <h4><a href="service_single.html">SU TASARRUFU</a></h4>
                                    <p>Su olmadan hiçbir canlı hayatını devam ettiremez. Her canlının her bitkinin suya ihtiyacı vardır. Bu nedenle her damla suyu dikkatli kullanmalıyız.  </p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</section>

  )
}

export default ServiceSection