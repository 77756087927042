import React, { useEffect, useState, useRef } from 'react'
import Layout from '../../components/Home/Layout'
import Spinner from '../../components/Home/Spinner'
const ImageList = () => {
    const [items, setItems] = useState([])
    const [errorMessage, setErrorMessage] = useState(false)
    const [length, setLength] = useState(0)
    const [title, setTitle] = useState('')
    const [image, setImage] = useState('')
    const [file, setFile] = useState('')
    const [loading, setLoading] = useState(true)
    const [imageModalIndex, setModalImageIndex] = useState(0)
    const [openPostModal, setOpenPostModal] = useState(false);

    const closeRef = useRef()

    const fetchData = async () => {
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/posts/image-list/`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            }
        })
        const data = await response.json()
        console.log(data)
        if (response.status === 200) {
            setItems(data)
            setLength(data.length)
        }
        else {
            setErrorMessage(true)
        }
        setLoading(false)
    }


    useEffect(() => {
        fetchData()
    }, [])


    const handleCreate = async () => {
        const form = new FormData()
        form.append('image', image)
        form.append('title', title)
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/posts/image-create/`, {
            method: 'POST',
            body: form
        })
        const data = await response.json()
        console.log(data)
        if (response.status === 201) {
            setItems([data, ...items])
            setLength(data.length)
            closeRef.current.click()
        }
        else {
            setErrorMessage(true)
        }
        setLoading(false)
    }
    const postModalOpen = (index) => {
        setModalImageIndex(index)
        setFile(items[imageModalIndex].image)
        setOpenPostModal(true)

    }
    const postModalClose = () => {
        setOpenPostModal(false)
    }

    const handlePrevImage = () => {
        setModalImageIndex((prevIndex) => prevIndex === 0 ? items.length - 1 : prevIndex - 1);
    };

    const handleNextImage = () => {
        setModalImageIndex((prevIndex) => (prevIndex + 1) % items.length);
    };

    return (
        <Layout>
            <section className="bg-page-header">
                <div className="page-header-overlay">
                    <div className="container">
                        <div className="row">
                            <div className="page-header">
                                <div className="page-title">
                                    <h2>Fotoğraf Galerimiz</h2>
                                </div>

                                <div className="page-header-content">
                                    <ol className="breadcrumb">
                                        <li><a href="/">ANASAYFA</a></li>
                                        <li>Galeri</li>
                                    </ol>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </section>




            <section className="bg-recent-project-home3">
                <div className="container">
                    <div className="row">

                        {
                            localStorage.getItem('token') &&
                            <div className='d-flex justify-content-lg-center mt-3'>


                                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <span style={{ marginRight: '10px' }}>Fotoğraf Yükle</span>
                                    <span><i className="fa fa-plus" aria-hidden="true"></i></span>
                                </button>

                            </div>

                        }
                        <div className="recent-project photo-gallery">
                            {
                                length === 0 &&
                                <div className="row">
                                    <div className='d-flex justify-content-center col-12'>
                                        <h2 className='text-center'>Fotoğraf Galerimiz Yakında Sizlerle...</h2>

                                    </div>
                                    <div className='d-flex justify-content-center col-12 mt-3'>
                                        <Spinner />
                                    </div>
                                </div>
                            }
                            <div className="portfolio-items portfolio-items-home3">

                                {
                                    items.map((item, index) =>

                                        <div className="item cat-1 mb-5" data-category="transition" key={index}>
                                            <div className="item-inner" style={{ cursor: 'pointer' }} onClick={() => postModalOpen(index)}>
                                                <div className="portfolio-img">
                                                    <div className="overlay-project"></div>

                                                    <img className='custom-portfolio-img' src={item.image} alt="recent-project-img-1" />
                                                    <div className="project-plus">
                                                        <a href='#'><i className="fa fa-plus" aria-hidden="true"></i></a>
                                                    </div>
                                                    <div className="recent-project-content">
                                                        <p>{item.title}</p>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    )
                                }




                            </div>


                        </div>

                    </div>

                </div>

            </section>




            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Fotoğraf Yükle</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className="form-group">

                                <input type="text" required onChange={(e) => setTitle(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="Başlık" />
                            </div>
                            <div className="form-group mt-3">

                                <input type="file" required onChange={(e) => setImage(e.target.files[0])} className="form-control-file" id="exampleFormControlFile1" />
                            </div>

                        </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" ref={closeRef}>Kapat</button>
                        <button type="button" class="btn btn-primary" onClick={handleCreate} >Kaydet</button>
                    </div>
                </div>
            </div>
        </div>






            {/* modal image */ }
    {
        openPostModal &&

            <div className='modalContainer'>
                <div className='closeButton' onClick={postModalClose}>
                    <svg className='closeButton' viewBox="0 96 960 960"><path d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" /></svg>
                </div>
                <div className='postFile'>
                    <img src={items[imageModalIndex].image} alt='postImage' />
                </div>
                <div className='backButton'>
                    <svg onClick={handlePrevImage} viewBox="0 -960 960 960" ><path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" /></svg>
                </div>
                <div className='nextButton'>
                    <svg onClick={handleNextImage} viewBox="0 -960 960 960" ><path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" /></svg>
                </div>
            </div>
    }
        </Layout >
    )
}

export default ImageList