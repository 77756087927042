import React from 'react'
import Layout from '../../components/Home/Layout'

const Team = () => {
    return (
        <Layout>

            <section class="bg-team-section">
                <div class="container">
                    <div class="row">
                        <h2 className='text-center'>EHA KURUMU TAKIMI</h2>
                        <div class="volunteers-option">
                            <div class="row">
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="volunteers-items">
                                        <div class="volunteers-img">
                                            <img src="assets/images/home01/volunteers-img-1.jpg" alt="volunteers-img-1" class="img-responsive" />
                                        </div>

                                        <div class="volunteers-content">
                                            <h4><a href="team_single.html">ECE</a></h4>
                                            <p>KURUCU & YÖNETİCİ</p>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="volunteers-items">
                                        <div class="volunteers-img">
                                            <img src="assets/images/home01/volunteers-img-2.jpg" alt="volunteers-img-1" class="img-responsive" />
                                        </div>

                                        <div class="volunteers-content">
                                            <h4><a href="team_single.html">HAZAL</a></h4>
                                            <p>KURUCU & YÖNETİCİ</p>
                                        </div>

                                    </div>

                                </div>


                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="volunteers-items">
                                        <div class="volunteers-img">
                                            <img src="assets/images/home01/volunteers-img-2.jpg" alt="volunteers-img-1" class="img-responsive" />
                                        </div>

                                        <div class="volunteers-content">
                                            <h4><a href="team_single.html">ARYA</a></h4>
                                            <p>KURUCU & YÖNETİCİ</p>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </section>


        </Layout>
    )
}

export default Team