import React, { useState, useEffect } from 'react'
import Loading from './Loading';
import { Link } from 'react-router-dom';
import LiveClockUpdate from './Clock';

const Navbar = () => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const t = setTimeout(() => {
            setLoading(false);
        }, 500);
        return () => {
            clearTimeout(t);
        }
    }, [])

    return (

        <div className="box-layout">

            <header className="header-style-2">
                <div className="bg-header-top">
                    <div className="container">
                        <div className="row">
                            <div className="header-top">
                                <ul className="h-contact">
                                    <li><i className="flaticon-time-left"></i> {new Date().toLocaleDateString()}</li>
                                    <li> <LiveClockUpdate /></li>
                                </ul>
                                <div className="donate-option">
                                    <a href="donate.html"><i className="fa fa-heart" aria-hidden="true"></i> Bize Destek Olun</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-main-menu menu-scroll">
                    <div className="container">
                        <div className="row">
                            <div className="main-menu">
                                <div className="main-menu-bottom">
                                    <div className="navbar-header">
                                        <Link className="navbar-brand" to="/">
                                            <span style={{ color: 'green', fontSize: '2rem', fontWeight: '600' }}>EHA </span>
                                            <span style={{ color: 'green', fontSize: '1rem', fontWeight: '600' }}>Kurumu</span>
                                        </Link>
                                        <button type="button" className="navbar-toggler collapsed d-lg-none" data-bs-toggle="collapse" data-bs-target="#bs-example-navbar-collapse-1" aria-controls="bs-example-navbar-collapse-1" aria-expanded="false">
                                            <span className="navbar-toggler-icon">
                                                <i className="fa fa-bars"></i>
                                            </span>
                                        </button>
                                    </div>
                                    <div className="main-menu-area">
                                        <div className="navbar-collapse collapse" id="bs-example-navbar-collapse-1">
                                            <ul>
                                                <li>
                                                    <Link to="/" className="active">ANASAYFA</Link>
                                                </li>
                                                {
                                                    localStorage.getItem('token') &&

                                                    <>
                                                        
                                                    
                                                            <li><a href="/blog-create"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Blog Oluştur</a></li>
                                                            <li><a href="project-create"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Proje Oluştur</a></li>
                                                        
                                                    </>

                                                }


                                                <li><a href="/team">HAKKIMIZDA</a></li>
                                                <li>
                                                    <a href="/project-list">PROJELER</a>
                                                </li>
                                                <li>
                                                    <a href="/blog-list">BLOGLAR</a>

                                                </li>
                                                <li>
                                                    <a href="/image-gallery">GALERİ</a>
                                                </li>
                                                {
                                                    !localStorage.getItem('token') &&
                                                    <li>
                                                        <a href="/login">GİRİŞ</a>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {
                loading &&
                <Loading />
            }
        </div>


    )
}

export default Navbar
