import React from 'react'

const Section = () => {
    return (
        <section className="bg-about-greenforest">
            <div className="container">
                <div className="row">
                    <div className="about-greenforest">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="about-greenforest-content">
                                    <h2>EHA KURUMU HAKKINDA</h2>

                                    <p><span>Hoş Geldiniz: Doğa Dostu Yaşam!</span></p>
                                    <p>Doğamız, bizlere yaşam kaynağı olarak hizmet ediyor, bize nefes aldırıyor ve muazzam bir güzellik sunuyor.
                                        Ancak, günümüzde çevresel sorunlar ve doğal dengenin bozulması, doğamızın kırılganlığını ortaya koyuyor.
                                        İşte bu nedenle, bizler "EHA KURUMU" adlı platformda bir araya geldik.
                                        Amacımız; doğayı korumak, sürdürülebilir bir gelecek için çaba göstermek ve doğal kaynakları daha bilinçli bir şekilde kullanmak.
                                        EHA KURUMU, yeşil bir gelecek için adım atmaya kararlı bir topluluktur. Sitemizde, doğa dostu yaşam tarzını benimseyenler için bilgiler, ipuçları ve ilham verici içerikler bulacaksınız.
                                        Sürdürülebilir tarım, geri dönüşüm, enerji tasarrufu ve doğa ile uyumlu yaşam konularında kapsamlı içerikler sunarak, her birimizin küçük adımlarla bile olsa büyük değişimlere katkı sağlayabileceğine inanıyoruz.
                                    </p>
                                    <a href="#" className="btn btn-default">BİZE KATILIN</a>
                                    <a href="#" className="btn btn-default">ABONE OLUN</a>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="about-greenforest-img">
                                    <img src="assets/images/home02/about-greenforet-img.jpg" alt="about-greenforet-img" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Section