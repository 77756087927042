import React, { useState } from 'react'
import Layout from '../../components/Home/Layout'
import Spinner from '../../components/Home/Spinner'
import { Link } from 'react-router-dom'
const ProjectCreate = () => {

  const [image, setImage] = useState('')
  const [caption, setCaption] = useState('')
  const [content, setContent] = useState('')
  
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)


  const handleCreate = async (e) => {
    setLoading(true)
    e.preventDefault()
    const form = new FormData()
    form.append('caption', caption)
    form.append('content', content)
  
    form.append('image', image)

    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/posts/project-create/`, {
      method: 'POST',
      body: form
    })
    const data = await response.json()
    console.log(data)
    if (response.status === 201) {
      setSuccessMessage(true)
    }
    else {
      setErrorMessage(true)
    }
    setLoading(false)

  }
  if (loading) {
    return (
      <Layout>
        <div className='container'>


          <h2 className='text-center text-dark mt-5'>Kaydediliyor...</h2>

          <div style={{ display: 'flex', height: '60vh', alignItems: 'center', justifyContent: 'center' }}>
            <Spinner />
          </div>
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mt-4'>
            <h2 className='text-center'>Proje Oluştur</h2>
          </div>
          {
            successMessage &&
            <div className='col-12 bg-success'>
              <p className='text-center text-white'>Başarıyla Oluşturuldu.</p>
              <div className='d-flex justify-content-center'>

                <Link className='text-center text-white' to='/project-list'>Proje Listesine Git</Link>
              </div>
            </div>
          }
          <div className='col-12 mt-4'>
            <form onSubmit={handleCreate}>
              <div className="form-group mt-4">

                <input type="file" className="form-control" required onChange={(e) => setImage(e.target.files[0])} />
              </div>

              <div className="form-group mt-4">

                <input type="text" className="form-control form-control-lg p-2" id="exampleFormControlInput1" required onChange={(e) => setCaption(e.target.value)} placeholder="Başlık" />
              </div>


              <div className="form-group mt-4">

                <textarea className="form-control" style={{ resize: 'none' }} placeholder='Proje İçeriği' required onChange={(e) => setContent(e.target.value)} id="exampleFormControlTextarea1" rows="13"></textarea>
              </div>
              <div className="form-group d-flex justify-content-center mt-4">

                <button type="submit" className="btn btn-primary mb-2">Kaydet</button>
              </div>

            </form>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default ProjectCreate