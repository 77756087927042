import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../components/Home/Layout';
import Spinner from '../../components/Home/Spinner';

const ProjectUpdate = () => {

    const [item, setItem] = useState([]);
    const [caption, setCaption] = useState('');
    const [content, setContent] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState();
    const [errorMessage, setErrorMessage] = useState(false);
    const [loading, setLoading] = useState(false)
    const { slug } = useParams()

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/posts/project-detail/${slug}/`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            }
        })
        const data = await response.json()
        console.log(data)
        if (response.status === 200) {
            setItem(data)
            setCaption(data.caption)
            setContent(data.content)            

        }
        else {
            setErrorMessage(true)
        }

    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleUpdate = async (e) => {
        setLoading(true)
        e.preventDefault()
        const form = new FormData()
        form.append('caption', caption)
        form.append('content', content)

        if (image) {

            form.append('image', image)
        }
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/posts/project-update/${slug}/`, {
            method: 'PATCH',
            body: form
        })
        const data = await response.json()
        console.log(data)
        if (response.status === 200) {
            setItem(data)
        }
        else {
            setErrorMessage(true)
        }
        setLoading(false)

    }

    if (loading) {
        return (
            <Layout>
                <div className='container'>


                    <h2 className='text-center text-dark mt-5'>Güncelleniyor...</h2>

                    <div style={{ display: 'flex', height: '60vh', alignItems: 'center', justifyContent: 'center' }}>
                        <Spinner />
                    </div>
                </div>
            </Layout>
        )
    }
    return (
        <Layout>
            <div className="container">
                <div className="row">
                    <div className="col-12 mt-5">
                        <h2 className='text-center'>{item.caption}</h2>
                    </div>
                    <div className='col-12 mt-5' style={{ width: '100%', height: '400px' }}>
                        <img className='img-responsive' style={{ height: '100%', objectFit: 'cover' }} src={item.image} alt='Proje Resmi' />
                    </div>
                    <form>
                        <div className="form-group mt-4">
                            <input type='file' onChange={(e) => setImage(e.target.files[0])} />
                        </div>

                        <div className="form-group mt-4">
                            <label>Başlık:</label>

                            <input className="form-control form-control-lg text-dark p-3" onChange={(e) => setCaption(e.target.value)} required type="text" defaultValue={item.caption} />
                        </div>

                        <div className="form-group mt-4">
                            <label>Proje İçeriği:</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" style={{ resize: 'none' }} onChange={(e) => setContent(e.target.value)} required defaultValue={item.content} rows="13"></textarea>
                        </div>
                        <div className="form-group d-flex justify-content-center mt-4">

                            <button type="submit" onClick={handleUpdate} className="btn btn-primary mb-2">Güncelle</button>
                        </div>

                    </form>
                </div>
            </div>

        </Layout>
    );
};

export default ProjectUpdate;
