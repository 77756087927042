import React from 'react'

const CountSection = ({ caption, text }) => {
    return (
        <section className="bg-count2-section">
            <div className="count-overlay">
                <div className="container">
                    <div className="row">
                        <div className="count-option">
                            <div className="row">
                                <div className="">
                                    <h2 className='text-center text-white'>{caption}</h2>
                                    <p className='text-center text-white'>{text} </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default CountSection