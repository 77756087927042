import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import HomePage from './pages/Home/HomePage';
import RootRouter from './RootRoter';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RootRouter>
    <HomePage />
  </RootRouter>
);
