import React from 'react'
import Layout from '../../components/Home/Layout'
import CarouselSection from './CarouselSection'
import Section from './Section'
import ProjectSection from './ProjectSection'
import CountSection from './CountSection'
import ServiceSection from './ServiceSection'
import BlogSection from './BlogSection'
import FooterSection from './FooterSection'

const HomePage = () => {
    return (
        <Layout>
            <CarouselSection>
            </CarouselSection>
            <Section>
            </Section>
            <CountSection caption={'PROJELERİMİZ'} text={'Doğa ve Çevremizi Korumak ve Geliştirmek Adına Planladığımız Birçok Proje Planlıyoruz. Lütfen Projelerimizi Takip Etmeyi ve Destek Olmayı Unutmayın.'}>
            </CountSection>
            <ProjectSection>
            </ProjectSection>

                <CountSection caption={'BİZ NE YAPIYORUZ?'} text={'TOPLUM VE NESLİMİZİN DOĞA VE ÇEVREYE VERDİĞİ ZARARI EN AZA İNDİRMEK VE BU KONUDA KİŞİSEL SORUMLULUKLARINA DİKKAT ÇEKMEK İSTİYORUZ.'}>
            </CountSection>
            <ServiceSection>
            </ServiceSection>
            
                <CountSection caption={'BLOG KÖŞEMİZ'} text={'Çevre ve Doğa ile ilgili güncel yazıları takip edin, çevre dostu etkinliklere katılın ve küresel çevre sorunlarına dair farkındalık yaratmaya destek olun.'}>
            </CountSection>
            <BlogSection>
            </BlogSection>
            <FooterSection>
                
            </FooterSection>
        </Layout>
    )
}

export default HomePage