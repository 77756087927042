import React,{useEffect,useState} from 'react'
import Layout from '../../components/Home/Layout'
import { Link, useParams, useNavigate } from 'react-router-dom'
const ProjectDetail = () => {

    const [item,setItem] = useState([])
    const [loading, setLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState(false)
    const {slug} = useParams()
    const navigate = useNavigate()
    const fetchData = async () => {
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/posts/project-detail/${slug}/`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            }
        })
        const data = await response.json()
        console.log(data)
        if (response.status === 200) {
            setItem(data)
        }
        else {
            setErrorMessage(true)
        }
        setLoading(false)
    }
    
    useEffect(()=>{
        fetchData()
    },[])
    return (
        <Layout>
            <section className="bg-page-header">
                <div className="page-header-overlay">
                    <div className="container">
                        <div className="row">
                            <div className="page-header">
                                <div className="page-title">
                                    <h2>{item.caption}</h2>
                                </div>

                                <div className="page-header-content">
                                    <ol className="breadcrumb">
                                        <li><a href="/">ANASAYFA</a></li>
                                        <li>< Link onClick={()=>navigate(-1)}>PROJELER</Link></li>
                                        <li>{item.caption}</li>
                                    </ol>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </section>


            <section className="bg-single-project">
                <div className="container">
                    <div className="row">
                        <div className="single-project">
                            <div className="row">
                                <div className="col-lg-12">
                                    <img src={item.image} alt="single-project-img-1" className="img-responsive" />
                                    <div className="single-pro-main-content">
                                        <div className="row">

                                            <div className="col-lg-12">
                                                <div className="single-project-content">
                                                    <h3 className='text-center'>Proje Açıklaması</h3>
                                                    <p style={{textAlign:'end'}}>{item.date}</p>
                                                    <p style={{textAlign:'justify',fontSize:'1.1rem',color:'#000'}} className='text-justify'>
                                                        {
                                                            item.content
                                                        }
                                                    </p>
                                                    
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </div>


                            </div>

                        </div>

                    </div>

                </div>

            </section>

        </Layout>
    )
}

export default ProjectDetail