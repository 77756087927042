import React, { useEffect, useState } from 'react'
import Spinner from '../../components/Home/Spinner'
const BlogSection = () => {
    const [items, setItems] = useState([])
    const [errorMessage, setErrorMessage] = useState('')
    const [length, setLength] = useState(0)
    const [loading, setLoading] = useState(true)

    const fetchData = async () => {
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/posts/blog-short-list/`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            }
        })
        const data = await response.json()
        console.log(data)
        if (response.status === 200) {
            setItems(data)
            setLength(data.length)
        }
        else {
            setErrorMessage(true)
        }
        setLoading(false);

    }
    useEffect(() => {
        fetchData()

    }, [])


    if (errorMessage) {
        return (
            <section className="bg-recent-project">
                <div className="container">
                    <div className="row">
                        <div className="recent-project">
                            <div className="section-header">

                                <h4 className='text-center text-success mt-4'>BİR HATA OLUŞTU!</h4>

                                <button className='btn btn-default mt-4 text-dark' onClick={fetchData}>{loading ? 'Yenileniyor' : 'Yenile'}</button>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        )
    }

    if (!errorMessage && length === 0) {
        return (
            <section className="bg-recent-project">
                <div className="container">
                    <div className="row">
                        <div className="recent-project">
                            <div className="section-header">

                                <h4 className='text-center text-success mt-4'>BLOGLARIMIZ YAZIM AŞAMASINDA...</h4>
                                <Spinner></Spinner>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }


    return (
        <section className="bg-blog-section mt-4">
            <div className="container">
                <div id="filters" className="button-group ">
                            <a href='/blog-list' className="button is-checked text-success">HEPSİNİ GÖSTER</a>
                        </div>

                <div className="row">
                    <div className="blog-section blog-page">
                        <div className="row">
                            {
                                items.map((item, index) =>
                                    <div className="col-lg-4 col-sm-6 col-12" key={index}>
                                        <div className="blog-items">
                                            <div className="blog-img">
                                                <a href={`/blog-detail/${item.slug}`}><img src={item.image} alt="blog-img-1" className="img-responsive" /></a>
                                            </div>

                                            <div className="blog-content-box">
                                                <div className="blog-content">
                                                    <h4><a href={`/blog-detail/${item.slug}`}>{item.caption}</a></h4>
                                                    <p>{item.description}</p>
                                                </div>

                                                <div className="meta-box">
                                                    <ul className="meta-post">
                                                        <li><i className="fa fa-calendar" aria-hidden="true"></i> {new Date(item.date).toLocaleDateString()}</li>

                                                        <li><a href={`/blog-detail/${item.slug}`}><i className="fa fa-commenting-o" aria-hidden="true"></i> {item.commentCount} Yorum</a></li>
                                                    </ul>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default BlogSection
