import React, { useEffect, useState } from 'react'
import Layout from '../../components/Home/Layout'
import { useNavigate, useParams } from 'react-router-dom'
import BlogCommentList from './BlogCommentList'


const BlogDetail = () => {
  const [item, setItem] = useState([])
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState(false)
  const { slug } = useParams()
  const navigate = useNavigate()

  const fetchData = async () => {
    setLoading(true)
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/posts/blog-detail/${slug}/`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
      }
    })
    const data = await response.json()
    console.log(data)
    if (response.status === 200) {
      setItem(data)
    }
    else {
      setErrorMessage(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Layout>

      <section className="bg-single-blog">
        <div className="container">
          <div className="row">
            <div className="single-blog">
              <div className="row">
                <div className="col-lg-12">
                  <div className="blog-items">
                    <div className="blog-img">
                      <img style={{ height: '400px', objectFit: 'cover' }} src={item.image} alt="blog-img-10" className="img-responsive" />
                    </div>

                    <div className="blog-content-box">
                      <div className="meta-box">

                        <ul className="meta-post">
                          <li><i className="fa fa-calendar" aria-hidden="true"></i> {new Date(item.date).toLocaleDateString()}</li>

                          <li><a href="#"><i className="fa fa-commenting-o" aria-hidden="true"></i> {item.commentCount} Yorum</a></li>
                          {
                            localStorage.getItem('token') &&

                            <li><a href={`/blog-update/${item.slug}`}><i className="fa fa-pencil" aria-hidden="true"></i> Güncelle</a></li>
                          }

                        </ul>
                      </div>

                      <div className="blog-content">
                        <h4 className='text-center'>{item.caption}</h4>


                        {item.content && item.content.split('\n').map((paragraph, index) => (
                          <p key={index} style={{ textIndent: '30px', textAlign: 'justify', letterSpacing: '1px', fontSize: '1.1rem', color: '#000' }}>{paragraph}</p>
                        ))}


                      </div>

                    </div>

                  </div>
                  <BlogCommentList id={item.id} />


                </div>
              </div>

            </div>

          </div>

        </div>

      </section>

    </Layout>
  )
}

export default BlogDetail