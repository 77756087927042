import React from 'react'
import Layout from '../../components/Home/Layout'
import { Link } from 'react-router-dom'
const NotFound = () => {
    return (
        <Layout title='Not Found'>
            
            <div className="container">
                <div className="row">
                    <div className="page-error-option">
                        <img src="../assets/images/home01/Greenforest-404-page-img.png" alt="Greenforest-404-page-img" className="img-responsive" />
                        <h2><span>Oops,</span> Aradığınız Sayfayı Bulamadık!</h2>
                        <p>Üzgünüz! Bu Bağlantı Bozuk veya Kullanılmıyor.</p>
                        <Link to="/" className="btn btn-default">ANASAYFA</Link>
                    </div>

                </div>

            </div>

        </Layout>
    )
}

export default NotFound