import React from 'react'

const CarouselSection = () => {
  return (
    <section className="bg-slider-option">
        <div className="slider-option slider-two">
            <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="10000">
                        <div className="slider-item">
                            <img src="assets/images/home02/slider-img-5.jpg" alt="bg-slider-2"/>
                            <div className="slider-content-area">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6"></div>
                                        <div className="col-md-6">
                                            <div className="slider-content">
                                                <h3>EHA KURUMUNA HOŞGELDİNİZ</h3>
                                                <h2>BİRLİKTE DÜNYAYI KURTARALIM</h2>                                                
                                                <p>DOĞAYI KORUYARAK!</p>
                                                <div className="slider-btn">
                                                    <a href="#" className="btn btn-default">BİZE KATILIN</a>
                                                    <a href="#" className="btn btn-default">ABONE OLUN</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="2000">
                        <div className="slider-item">
                            <img src="assets/images/home02/slider-img-4.jpg" alt="bg-slider-2"/>
                            <div className="slider-content-area">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6"></div>
                                        <div className="col-md-6">
                                            <div className="slider-content">
                                                <h3>EHA KURUMUNA HOŞGELDİNİZ</h3>
                                                <h2>ÇEVREMİZİ TEMİZ TUTALIM</h2>
                                                <p>ÇEVREMİZİ KORUYALIM</p>
                                                <div className="slider-btn">
                                                    <a href="#" className="btn btn-default">BİZE KATILIN</a>
                                                    <a href="donate.html" className="btn btn-default">ABONE OLUN</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="slider-item">
                            <img src="assets/images/home02/slider-img-6.jpg" alt="bg-slider-2"/>
                            <div className="slider-content-area">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6"></div>
                                        <div className="col-md-6">
                                            <div className="slider-content">
                                                <h3>EHA KURUMUNA HOŞGELDİNİZ</h3>
                                                <h2>HAYVANLARI SEVELİM</h2>
                                                <p>HAYVANLARI KORUYALIM!</p>
                                                <div className="slider-btn">
                                                    <a href="#" className="btn btn-default">BİZE KATILIN</a>
                                                    <a href="donate.html" className="btn btn-default">ABONE OLUN</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="left carousel-control carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="right carousel-control carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    </section>

  )
}

export default CarouselSection